import { log } from '@seeeverything/ui.util/src/log/log.ts';
import getConfig from 'next/config.js';

// Remove once configured correctly everywhere.
log.debug(
  'Using internal login url: ',
  getConfig().serverRuntimeConfig.INTERNAL_LOGIN_URL ??
    getConfig().publicRuntimeConfig.LOGIN_URL,
);

export const getExternalLoginServiceUrl = (): string => {
  let baseUrl = getConfig().publicRuntimeConfig.LOGIN_URL;
  if (baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0, -1);
  return baseUrl;
};

export const getInternalLoginServiceUrl = (): string => {
  let baseUrl = getConfig().serverRuntimeConfig.INTERNAL_LOGIN_URL;

  // To make non-breaking. Remove when configured everywhere.
  if (!baseUrl) return getExternalLoginServiceUrl();

  if (baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0, -1);
  return baseUrl;
};
