import { IS_BROWSER } from '../constants/constants.ts';
import { ILocalStorage } from './types.ts';
import { log } from '../log/log.ts';

/**
 * Interface to the browser's local-storage.
 */
export function init(): ILocalStorage {
  if (IS_BROWSER) {
    const store = window.localStorage;
    return {
      getItem(key: string) {
        return store.getItem(key);
      },
      setItem(key: string, value: any) {
        try {
          store.setItem(key, value);
        } catch (err) {
          log.error(`Error setting local storage key: '${key}'`, err);
          store.clear();
          throw err;
        }
      },
      removeItem(key: string) {
        store.removeItem(key);
      },
      findKeys(startsWith: string) {
        return Object.keys(store).filter((key) => key.startsWith(startsWith));
      },
    };
  } else {
    // Not in browser, return no-op.
    return {
      getItem() {
        return undefined;
      },
      setItem() {
        return undefined;
      },
      removeItem() {
        return undefined;
      },
      findKeys() {
        return [];
      },
    };
  }
}
