import { DatadogInitOptions } from './datadog/types.ts';
import { MonitoringUser } from './types.ts';

export abstract class RumMonitor {
  protected _isInitialized = false;
  protected _isCrashReportingEnabled = false;
  protected _isRumEnabled = false;

  abstract init(options: DatadogInitOptions): void;
  abstract enableRum(): void;
  abstract enableCrashReporting(): void;
  abstract setUser(user: MonitoringUser): void;
  abstract setCustomData(customData: {}): void;
  abstract addAction(action: string, context?: object): void;
  abstract addPageView(path: string, context?: object): void;
  abstract addError(...items: any[]): void;

  public get isCrashReportingEnabled() {
    return this._isCrashReportingEnabled;
  }
  public get isRumEnabled() {
    return this._isRumEnabled;
  }

  protected checkInitialized() {
    return this._isInitialized;
  }

  protected checkRum() {
    if (!this.checkInitialized()) return false;
    return this._isRumEnabled;
  }

  protected checkCrashReporting() {
    if (!this.checkRum()) return false;
    return this._isCrashReportingEnabled;
  }
}
