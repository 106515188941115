/* eslint-disable no-console */
import { monitoring } from '../monitoring/index.ts';

const write = (method: 'log' | 'warn' | 'error', items: any[]) => {
  if (global.console && !log.silent) {
    console[method](...items);
  }
};

/**
 * Stub log shim.
 * Pipe these log items into a proper service log.
 */
export const log = {
  silent: process.env.DEBUG === 'true',
  info(...items: any[]) {
    write('log', items);
  },
  TODO(...items: any[]) {
    write('warn', ['TODO:', ...items]);
  },
  debug(...items: any[]) {
    write('log', items);
  },
  warn(...items: any[]) {
    write('warn', items);
  },
  error(...items: any[]) {
    monitoring.addError(...items);
    write('error', items);
  },

  group(...items: any[]) {
    if (console.group) {
      console.group(...items);
    } else {
      log.info(items);
    }
  },
  groupEnd() {
    console.groupEnd();
  },
};
