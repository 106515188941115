import { ILocalStorageValue } from './types.ts';
import { init as initStore } from './store.web.ts';

const store = initStore();

function toValue(key: string, json: string): any {
  let item: ILocalStorageValue;

  // Convert the JSON string to an object.
  try {
    item = JSON.parse(json);
  } catch (error) {
    throw new Error(
      `Failed to read '${key}' from storage. The value '${json}' cannot be parsed.`,
    );
  }

  let result;

  // Perform type conversions.
  switch (item.type) {
    case 'null':
    case 'bool':
    case 'string':
      result = item.value;
      break;
    case 'number':
      result = parseFloat(item.value);
      break;
    case 'date':
      result = new Date(item.value);
      break;
    case 'object':
      result = item.value;
      break;
    default: // Ignore.
  }

  return { key, value: result };
}

/**
 * Returns items in local storage that have a key starting with the
 * provided input.
 */
export async function findItems(startsWith: string): Promise<any> {
  const keys = await store.findKeys(startsWith);
  const result = keys
    .map(async (key) => {
      const json = await store.getItem(key);
      return json ? toValue(key, json) : undefined;
    })
    .reduce(async (accP, itemP) => {
      const item = await itemP;
      const acc: any = await accP;
      acc[item.key] = item.value;
      return acc;
    }, {});

  return result;
}
