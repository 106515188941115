import { Observable } from 'rxjs';
import { prop } from './prop.ts';
import { findItems } from './findItems.ts';
import { changes$ } from './events.rx.ts';
import {
  ILocalStorage,
  ILocalStorageProp,
  ILocalStoragePropOptions,
  ILocalStorageChangedEvent,
} from './types.ts';
import { clearKey } from './clearKey.ts';

export type {
  ILocalStorage,
  ILocalStorageProp,
  ILocalStoragePropOptions,
  ILocalStorageChangedEvent,
};

export interface ILocalStorageAccess {
  prop: <T>(
    key: string,
    value?: T | null,
    options?: ILocalStoragePropOptions<T>,
  ) => T | undefined;
  findItems: (startsWith: string) => any;
  changes$: Observable<ILocalStorageChangedEvent>;
  clearKey: (key: string) => void;
}

export const localStorage: ILocalStorageAccess = {
  prop,
  findItems,
  changes$,
  clearKey,
};
