// Note: import order is important - do not change.
import getConfig from 'next/config.js';
import { Amplify, Auth } from 'aws-amplify';
import moment from 'moment';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { storageApi } from '@seeeverything/ui.util/src/storage/api.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { tenants } from '../util/tenant.ts';

let isInit = false;

const initialize = async () => {
  if (isInit) return;
  if (typeof window === 'undefined') return;

  isInit = true;

  const storageSettings =
    process.env.NODE_ENV === 'production'
      ? {
          cookieStorage: { domain: window.location.hostname, secure: true },
          localStorage: false,
          sessionStorage: false,
        }
      : {
          cookieStorage: false,
          localStorage: { domain: window.location.hostname },
          sessionStorage: false,
        };

  Amplify.configure({
    Auth: {
      region: getConfig().publicRuntimeConfig.AWS_REGION,
      userPoolId: getConfig().publicRuntimeConfig.USER_POOL_ID,
      userPoolWebClientId:
        getConfig().publicRuntimeConfig.USER_POOL_WEB_CLIENT_ID,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: getConfig().publicRuntimeConfig.COGNITO_AUTH_DOMAIN,
        redirectSignIn: `${window.location.origin}/auth/callback`,
        redirectSignOut: window.location.origin,
        responseType: 'code',
        scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      },
    },
    ...storageSettings,
  });
};

const getIsAuthenticated = async () => {
  if (typeof window === 'undefined') return true;

  await initialize();

  try {
    const session = await Auth.currentSession();
    if (!session?.isValid()) return false;

    const currentUser = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
    return Boolean(currentUser);
  } catch {
    return false;
  }
};

const getEmail = async () => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return;

  return (await Auth.currentSession())?.getIdToken?.()?.payload?.email;
};

const getEmailDomain = async () => {
  const email = await getEmail();
  if (!email) return;

  return email.split('@')?.[1];
};

const getIdToken = async () => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return;

  return (await Auth.currentSession()).getIdToken().getJwtToken();
};

const getAccessToken = async () => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return;

  return (await Auth.currentSession()).getAccessToken().getJwtToken();
};

const getExpiresAt = async () => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return;
  const expiresAtEpoch =
    (await Auth.currentSession()).getAccessToken().getExpiration() * 1000;
  return moment(expiresAtEpoch).toISOString();
};

const canChangePassword = async () => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return false;

  const hasExternalIdentities =
    (await Auth.currentSession()).getIdToken().payload?.['identities']?.length >
    0;
  return !hasExternalIdentities;
};

type ChangePasswordArgs = {
  currentPassword: string;
  newPassword: string;
  tenant: string;
  module: ModuleType;
};
const changePassword = async (args: ChangePasswordArgs) => {
  const isAllowed = await canChangePassword();
  if (!isAllowed) return { success: false, failReason: 'Not allowed' };

  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(
      currentUser,
      args.currentPassword,
      args.newPassword,
    );
    return { success: true };
  } catch (err) {
    const failReason =
      err.message === 'Incorrect username or password.'
        ? 'Please check your current password and try again.'
        : err.message;

    return { success: false, failReason };
  }
};

const logout = async (rememberReturnPath = false) => {
  if (typeof window !== 'undefined' && rememberReturnPath) {
    storageApi.returnPath.set(window.location.href);
  }

  try {
    await initialize();
    await Auth.signOut();
  } catch (err) {
    log.error(new Error(`An error occurred during sign out - ${err.message}`));
  }

  storageApi.clearAuthValues();
  storageApi.clearIsAuthenticated();

  if (typeof window !== 'undefined') {
    window.location.assign('/auth/login');
  }
};

const getTenants = async (client: IGraphQLClient) => {
  const isAuthenticated = await getIsAuthenticated();
  if (!isAuthenticated) return {};

  return tenants(client);
};

const getLoginRedirectUrl = async () => {
  await initialize();

  const clientId = getConfig().publicRuntimeConfig.USER_POOL_WEB_CLIENT_ID;
  const domain = getConfig().publicRuntimeConfig.COGNITO_AUTH_DOMAIN;
  const redirectSignIn = `${window.location.origin}/auth/callback`;
  return `https://${domain}/oauth2/authorize?client_id=${clientId}&response_type=code&scope=openid+email+profile+aws.cognito.signin.user.admin&redirect_uri=${redirectSignIn}`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleAuthCallback = async (_code: string, _clientId: string) => {
  await initialize();

  return storageApi.returnPath.readAndClear('/');
};

export const cognitoAuthApi = {
  canChangePassword,
  changePassword,
  getAccessToken,
  getEmail,
  getEmailDomain,
  getExpiresAt,
  getIdToken,
  getIsAuthenticated,
  getLoginRedirectUrl,
  getTenants,
  handleAuthCallback,
  logout,
};
