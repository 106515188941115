import { is } from 'ramda';
import { RumMonitor } from './rumMonitor.ts';
import { MonitoringUser } from './types.ts';

let monitors: RumMonitor[] = [];

export const monitoring = {
  create: (initializedMonitors: Array<RumMonitor | undefined>) =>
    (monitors = initializedMonitors.filter((monitor) =>
      Boolean(monitor),
    ) as RumMonitor[]),

  addError: (...items: any[]) => {
    const errors = items && items.length ? items.filter(is(Error)) : undefined;
    if (!errors) return;

    monitors.forEach((monitor) => monitor.addError(errors));
  },

  addAction: (action: string, context?: object) => {
    monitors.forEach((monitor) => monitor.addAction(action, context));
  },

  addPageView: (path: string, context?: object) => {
    monitors.forEach((monitor) => monitor.addPageView(path, context));
  },

  setUser: (user: MonitoringUser) => {
    monitors.forEach((monitor) => monitor.setUser(user));
  },

  setCustomData: (customData: {}) => {
    monitors.forEach((monitor) => monitor.setCustomData(customData));
  },
};
