import { Subject, share } from 'rxjs';

const sessionStorage = () =>
  typeof window === 'undefined' ? undefined : window.sessionStorage;

/**
 * Minimal API around session storage that provides safety when running server side.
 */
export const sessionStorageApi = {
  get: (key: string) => sessionStorage()?.getItem(key),
  set: (key: string, value: string) => {
    subject$.next({ key, value });
    if (value === null) sessionStorage()?.removeItem(key);
    else sessionStorage()?.setItem(key, value);
  },
};

export type SessionStorageChangeEvent = {
  key: string;
  value: string;
};

const subject$ = new Subject<SessionStorageChangeEvent>();
export const sessionStorageChanges$ = subject$.pipe(share());
