import * as R from 'ramda';
import {
  ILocalStoragePropOptions,
  ILocalStorageValue,
  LocalStorageValueType,
} from './types.ts';
import { init as initStore } from './store.web.ts';
import { subject$ } from './events.rx.ts';

const store = initStore();

function asTypeName(value: any): LocalStorageValueType {
  if (value === null) {
    return 'null';
  }
  if (R.is(Boolean, value)) {
    return 'bool';
  }
  if (R.is(String, value)) {
    return 'string';
  }
  if (R.is(Number, value)) {
    return 'number';
  }
  if (R.is(Boolean, value)) {
    return 'bool';
  }
  if (R.is(Date, value)) {
    return 'date';
  }
  return 'object';
}

function toValue(key: string, json: string): any {
  let item: ILocalStorageValue;

  // Convert the JSON string to an object.
  try {
    item = JSON.parse(json);
  } catch (error) {
    throw new Error(
      `Failed to read '${key}' from storage. The value '${json}' cannot be parsed.`,
    );
  }

  // Perform type conversions.
  switch (item.type) {
    case 'null':
    case 'bool':
    case 'string':
      return item.value;
    case 'number':
      return parseFloat(item.value);
    case 'date':
      return new Date(item.value);
    case 'object':
      return item.value;
    default: // Ignore.
  }
}

/**
 * Read/write interface to the store.
 *
 *    READ:  Pass nothing (undefined) to read the value for the `key` parameter.
 *    WRITE: Pass a `value` to write.
 *    CLEAR: Pass null to `value` to clear.
 */
export function prop<T>(
  key: string,
  value?: T | null,
  options: ILocalStoragePropOptions<T> = {},
): T | undefined {
  let result: any = value;

  const fireEvent = () => {
    subject$.next({ key, value });
  };

  if (value === null) {
    // CLEAR.
    store.removeItem(key);
    fireEvent();
  } else if (value !== undefined) {
    // WRITE.
    const storageValue: ILocalStorageValue = { value, type: asTypeName(value) };
    store.setItem(key, JSON.stringify(storageValue));
    fireEvent();
  } else {
    // READ.
    const json = store.getItem(key);
    result = json ? toValue(key, json) : undefined;
  }

  return result === undefined ? options.default : (result as T);
}
