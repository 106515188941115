import { init as initStore } from './store.web.ts';

const store = initStore();

/**
 * Clears a key/value pair from local storage.
 */
export function clearKey(key: string) {
  const existing = store.getItem(key);

  if (existing) {
    store.removeItem(key);
  }
}
