import Cookies from 'js-cookie';
import { localStorage } from '../local-storage/index.ts';
import { sessionStorageApi } from '../session-storage/api.ts';
import { ModuleType } from '../types.ts';

export enum Keys {
  AuthClient = 'com.seeeverything.client',
  AuthType = 'com.seeeverything.authType',
  Debug = 'com.seeeverything.debug.enabled',
  DebugFilterEnabled = 'com.seeeverything.debug.filter.enabled',
  DebugFilterText = 'com.seeeverything.debug.filter.text',
  Forms = 'com.seeeverything.forms',
  IsAuthenticated = 'auth/isAuthenticated',
  Module = 'com.seeeverything.module',
  Timezone = 'com.seeeverything.tenant.timezone',
  PinSticky = 'com.seeeverything.editor.isPinSticky',
  ReturnPath = 'TEMP/returnPath',
  TenantId = 'com.seeeverything.tenant.tenantId',
  TenantRememberSelection = 'com.seeeverything.tenant.rememberTenant',
  GridFilters = 'TEMP/gridFilters',
  IsPrimary = 'TEMP/isPrimary',
}

const get = <T>(key: string) => localStorage.prop<T>(key);
const set = <T>(key: string, value: T) => localStorage.prop<T>(key, value);
const clear = <T>(key: string) => localStorage.prop<T>(key, null);

const clearAuthValues = () => {
  Object.keys(Cookies.get()).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });

  const excludeKeys: string[] = [
    Keys.Debug,
    Keys.DebugFilterEnabled,
    Keys.DebugFilterText,
    Keys.Module,
    Keys.PinSticky,
    Keys.ReturnPath,
    Keys.TenantId,
    Keys.TenantRememberSelection,
  ];

  const excludeStartsWith: string = Keys.Forms;

  Object.keys(window.localStorage)
    .filter(
      (key) => !excludeKeys.includes(key) && !key.startsWith(excludeStartsWith),
    )
    .forEach((key) => {
      window.localStorage.removeItem(key);
    });
};

export const storageApi = {
  clearAuthValues,

  clearIsAuthenticated: () => clear<boolean>(Keys.IsAuthenticated),

  clearTenantState: () => {
    clear(Keys.TenantRememberSelection);
  },

  timezone: {
    getLocalStorage: () => get<string>(Keys.Timezone),
    getSessionStorage: () => sessionStorageApi.get(Keys.Timezone),
    set: (to: string) => {
      sessionStorageApi.set(Keys.Timezone, to);
      return set(Keys.Timezone, to);
    },
  },

  tenant: {
    getLocalStorage: () => get<string>(Keys.TenantId),
    getSessionStorage: () => sessionStorageApi.get(Keys.TenantId),
    set: (to: string) => {
      sessionStorageApi.set(Keys.TenantId, to);
      return set(Keys.TenantId, to);
    },
  },

  getTenantRememberSelection: () => get<boolean>(Keys.TenantRememberSelection),
  setTenantRememberSelection: (to: boolean) =>
    set<boolean>(Keys.TenantRememberSelection, to),

  module: {
    getLocalStorage: () => get<ModuleType>(Keys.Module),
    getSessionStorage: () => sessionStorageApi.get(Keys.Module) as ModuleType,
    set: (to: ModuleType) => {
      sessionStorageApi.set(Keys.Module, to);
      return set(Keys.Module, to);
    },
  },

  returnPath: {
    set: (to: string) => set<string>(Keys.ReturnPath, to),
    readAndClear: (defaultIfEmpty: string) => {
      const result = get<string>(Keys.ReturnPath) || defaultIfEmpty;
      clear(Keys.ReturnPath);
      return result;
    },
  },

  temp: {
    gridFilters: () => get<boolean>(Keys.GridFilters),
    enableGridFilters: () => set<boolean>(Keys.GridFilters, true),
    disableGridFilters: () => set<boolean>(Keys.GridFilters, false),
    unsetGridFilters: () => clear<boolean>(Keys.GridFilters),

    isPrimary: () => get<boolean>(Keys.IsPrimary),
    enableIsPrimary: () => set<boolean>(Keys.IsPrimary, true),
    disableIsPrimary: () => set<boolean>(Keys.IsPrimary, false),
    unsetIsPrimary: () => clear<boolean>(Keys.IsPrimary),
  },
};

export const changes$ = localStorage.changes$;
